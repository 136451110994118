import { RootState } from '@app/store';
import { ThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline/CssBaseline';
import { createTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import Maintenance from './Maintenance';
import AppRoute from './routes';
import themeSettings from './theme';

function App() {
  const mode = useSelector((state: RootState) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MO === 'true';

  return (
    <div className="app" style={{ height: '100%', maxHeight: '100%', minHeight: '100%', position: 'relative' }}>
      {isMaintenanceMode ? (
        <Maintenance />
      ) : (
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppRoute />
          </ThemeProvider>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
