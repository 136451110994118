import { formatReal } from '@/app/helpers/currency';
import { formatDate } from '@/app/helpers/date';
import { Invoice } from '@/app/models/invoice';
import InvoiceStatus from '@/app/models/invoiceStatus';
import { InvoicesIdsRequest } from '@/app/models/invoicesIdsRequest';
import { InvoicesProcessRequest } from '@/app/models/invoicesProcessRequest';
import { useDisableInvoiceMutation, useProcessInvoicesMutation } from '@/app/services/invoiceAPI';
import InfoIcon from '@mui/icons-material/Info';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ReportIcon from '@mui/icons-material/Report';
import { Box, Button, Checkbox, TablePagination, Tooltip, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AgencySynchronizeStatus from '../agencySync/AgencySynchronizeStatus';
import ActionMenu, { ItemAction } from '../common/ActionMenu';
import SearchField from '../common/SearchField';
import StandardDialog from '../common/StandardDialog';
import StatusReport from '../common/StatusReport';
import TableDialog from '../common/dialogs/TableDialog';

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  fontSize: 13,
  fontWeight: 'bold',
  paddingBottom: 1,
}));

const RowTableCell = styled(TableCell)({
  fontSize: 13,
});
/*
const useStyles = makeStyles((theme) => ({
  marginAutoContainer: {
    width: 500,
    height: 80,
    display: 'flex',
    backgroundColor: 'gold',
  },
  marginAutoItem: {
    margin: 'auto',
  },
  alignItemsAndJustifyContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
*/
type CreateExceptionTableProps = {
  rows: Array<Invoice>;
  page: number;
  setPage: (n: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (n: number) => void;
  count: number;
  search: (val: string) => void;
  searchTerm: string;
  notificationDisableInvoice: (b: boolean) => void;
};

function PendingInvoicesTable(props: CreateExceptionTableProps) {
  const { rows, count, rowsPerPage, setRowsPerPage, page, setPage, search, searchTerm, notificationDisableInvoice } =
    props;
  const { i18n, t } = useTranslation(['invoices', 'common']);
  const [selected, setSelected] = useState<number[]>([]);
  const [openProcessDialog, setOpenProcessDialog] = useState(false);
  const [openInvoiceDisableDialog, setOpenInvoiceDisableDialog] = useState(false);
  const [openInvoiceDetailDialog, setOpenInvoiceDetailDialog] = useState(false);
  const [invoiceDetailId, setInvoiceDetailId] = useState('');
  const [invoiceToDisableId, setInvoiceToDisableId] = useState('');
  const [buttonDisable, setButtonDisable] = useState(false);

  const [processInvoices] = useProcessInvoicesMutation();
  const [disableInvoice] = useDisableInvoiceMutation();

  const handleClick = (event: React.MouseEvent<unknown>, value: number) => {
    const selectedIndex = selected.indexOf(value);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: number[] = rows.filter((row) => row.haveInvalidProjects === false).map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleProcessClick = async () => {
    const invoicesToProcess: InvoicesProcessRequest = { ids: selected };
    await processInvoices(invoicesToProcess);
    toast.info(
      t('invoices.in.processing.wait', {
        position: toast.POSITION.TOP_RIGHT,
      }),
    );
  };

  const handleInvoiceDisableClick = async () => {
    const invoiceIds: InvoicesIdsRequest = { ids: [invoiceToDisableId] };
    await disableInvoice(invoiceIds);
    toast.info(
      t('invoices.in.disabled.wait', {
        position: toast.POSITION.TOP_RIGHT,
      }),
    );
    notificationDisableInvoice(true);
  };

  const handleInvoiceDetailModal = async (id: string) => {
    setInvoiceDetailId(id);
    setOpenInvoiceDetailDialog(true);
  };

  const handleInvoiceDisableModal = async (id: string) => {
    setOpenInvoiceDisableDialog(true);
    setInvoiceToDisableId(id);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  return (
    <Box sx={{ width: '100%' }}>
      <StandardDialog
        title={t('common:process')}
        contentText={t('confirm.process')}
        open={openProcessDialog}
        onClose={() => {
          setButtonDisable(false);
          setOpenProcessDialog(false);
        }}
        onConfirm={() => {
          setOpenProcessDialog(false);
          if (!buttonDisable) {
            handleProcessClick();
            setButtonDisable(true);
          }
        }}
        cancelText={t('common:no')}
        confirmText={t('common:yes')}
      />
      <TableDialog
        title={t('common:detail')}
        content={invoiceDetailId}
        open={openInvoiceDetailDialog}
        onClose={() => {
          setOpenInvoiceDetailDialog(false);
        }}
      />
      <StandardDialog
        title={t('common:disabled')}
        contentText={t('confirm.disabled')}
        open={openInvoiceDisableDialog}
        onClose={() => {
          setOpenInvoiceDisableDialog(false);
        }}
        onConfirm={() => {
          setOpenInvoiceDisableDialog(false);
          handleInvoiceDisableClick();
        }}
        cancelText={t('common:no')}
        confirmText={t('common:yes')}
      />
      <Box
        sx={{
          display: 'flex',
          marginTop: '16px',
          marginBottom: '16px',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <SearchField
          style={{ width: '30%' }}
          searchTerm={searchTerm}
          setSearchTerm={search}
          placeholder={t('search.by.agency.or.invoice-id') || ''}
        />
        <Box sx={{ display: 'flex' }}>
          <AgencySynchronizeStatus />
          <Button
            size="small"
            // disabled={selected.length === 0}
            onClick={() => {
              setOpenProcessDialog(true);
            }}
            variant="primary"
            style={{
              marginLeft: '5px',
            }}
          >
            {t('button.process')}
          </Button>
        </Box>
      </Box>
      <TableContainer>
        <Table size="small" aria-label="Exception Case Table">
          <TableHead>
            <TableRow>
              <HeaderTableCell padding="checkbox">
                <Checkbox
                  // indeterminate={selected.length > 0 && selected.length < rowsPerPage}
                  // checked={rowsPerPage > 0 && selected.length === rowsPerPage}
                  onChange={handleSelectAllClick}
                  inputProps={{ 'aria-label': 'select all' }}
                  sx={{
                    color: 'default',
                    '&.Mui-checked': {
                      color: '#e87305',
                    },
                  }}
                />
              </HeaderTableCell>
              <HeaderTableCell>{t('invoice.code')}</HeaderTableCell>
              <HeaderTableCell>{t('agency')}</HeaderTableCell>
              <HeaderTableCell>{t('total.expenses')}</HeaderTableCell>
              <HeaderTableCell>{t('invoice.status')}</HeaderTableCell>
              <HeaderTableCell>{t('invoice.date.collected')}</HeaderTableCell>
              <HeaderTableCell>{t('common:actions')}</HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow role="checkbox" aria-checked={isSelected(row.id)} tabIndex={-1} key={row.id.toString()}>
                <RowTableCell padding="checkbox">
                  <Checkbox
                    inputProps={{ 'aria-labelledby': row.id.toString() }}
                    name="id"
                    value={row.id.toString()}
                    checked={isSelected(row.id)}
                    onClick={(event) => handleClick(event, row.id)}
                    disabled={row.haveInvalidProjects}
                    sx={{
                      color: 'default',
                      '&.Mui-checked': {
                        color: '#e87305',
                      },
                    }}
                  />
                </RowTableCell>
                <RowTableCell>
                  <div style={{ alignItems: 'center', display: 'flex' }}>
                    {row.code}

                    {row.haveInvalidProjects && (
                      <Tooltip sx={{ marginLeft: '10px' }} title={t('invoice.with.invalid.item')}>
                        <ReportIcon fontSize="small" />
                      </Tooltip>
                    )}
                  </div>
                </RowTableCell>
                <RowTableCell> {row.agency.name}</RowTableCell>
                <RowTableCell> {formatReal(parseFloat(row.totalExpenses))} </RowTableCell>
                <RowTableCell>
                  <StatusReport status={row.status as InvoiceStatus} />
                </RowTableCell>
                <RowTableCell>{formatDate(row.collectedDate.toString(), i18n.language)} </RowTableCell>
                <RowTableCell>
                  <ActionMenu>
                    <ItemAction
                      text={t('common:detail')}
                      Icon={InfoIcon}
                      DividerLine
                      onClick={() => {
                        handleInvoiceDetailModal(row.id.toString());
                      }}
                    />
                    <ItemAction
                      text={t('common:disabled')}
                      Icon={RemoveCircleIcon}
                      onClick={() => {
                        handleInvoiceDisableModal(row.id.toString());
                      }}
                    />
                  </ActionMenu>
                </RowTableCell>
              </TableRow>
            ))}
            {rows.length === 0 && (
              <TableRow>
                <TableCell sx={{ textAlign: 'center' }} colSpan={8}>
                  <Typography variant="subtitle2">{t('not.exists.invoices.pending')}</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          sx={{ width: '100%' }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, p) => setPage(p)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(parseFloat(e.target.value));
            setPage(0);
          }}
          labelDisplayedRows={({ from, to, count: limit }) => `${from}-${to} ${t('common:of')} ${limit}`}
          labelRowsPerPage={t('common:itens.per.page')}
        />
      </TableContainer>
    </Box>
  );
}

export default PendingInvoicesTable;
